<template>
  <div class="container">
    <div class="hidden md:flex gallery">
      <div class="flex main-image-section items-center justify-center">
        <img :src="imageThumb" class="object-cover h-full w-auto" />
      </div>
      <div id="thumbnails" class="thumbnails space-y-4">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image.file_path"
          @click="changeImage(image.file_path)"
        />
      </div>
    </div>
    <div class="flex md:hidden flex-col w-full">
      <div class="flex pb-4 items-center justify-center">
        <img :src="imageThumb" class="object-cover h-full w-auto" />
      </div>
      <div id="thumbnails" class="flex flex-row space-x-4 overflow-x-scroll">
        <img
          v-for="(image, index) in images"
          :key="index"
          class="w-32 h-auto"
          :src="image.file_path"
          @click="changeImage(image.file_path)"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imgslider: {
      type: [Array],
    },
  },
  data() {
    return {
      currentImage: "",
    };
  },
  computed: {
    imageThumb() {
      if (this.currentImage) return this.currentImage;
      return (
        this.imgslider &&
        this.imgslider.length > 0 &&
        this.imgslider[0].file_path
      );
    },
    images() {
      return this.imgslider;
    },
  },
  methods: {
    changeImage(imageUrl) {
      console.log(imageUrl);
      this.currentImage = imageUrl;
    },
  },
};
</script>

<style scoped>
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
.container {
  height: 100%;
}

body {
  background: #272738;
  font-family: cursive;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  color: #fff;
}

.gallery {
  position: relative;
  width: 1000px;
  height: 450px;
  /* background: #7e7f9a; */
  border: 3px solid #f9f9f8;
  border-radius: 5px;
  overflow: hidden;
}

.main-image-section {
  width: 80%;
}

.main-view {
  width: 100%;
  opacity: 0;
  cursor: pointer;

  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.caption-container {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100px;
  bottom: -100px;
  opacity: 0;

  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.main-image-section:hover .caption-container {
  bottom: 0;
  opacity: 1;
}

#caption {
  font-size: 42px;
  color: #fff;
  padding: 10px;
}

#info {
  padding: 0 10px;
  color: #ccc;
}

.thumbnails {
  width: 20%;
  padding: 5px;
  overflow-y: auto;
}

.thumbnails img {
  width: 100%;
  opacity: 0.7;
  cursor: pointer;

  -webkit-transition: opacity 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}

.thumbnails img:hover {
  opacity: 1;
}

.thumbnails img:focus {
  opacity: 1;
}
</style>
