<template>
  <div
    class="flex flex-col contact_us width-full h-full bg-white justify-items-center py-10"
  >
    <gallery-item class="flex w-9/11 h-auto" :imgslider="images" />
    <div class="flex px-4 md:px-28" v-if="product">
      <h1 class="text-3xl md:text-5xl py-10">{{ product.product_name }}</h1>
    </div>
    <div class="flex flex-col px-4 md:px-28">
      <div>
        <p class="text-left text-xl">
          <span>ราคาพิเศษ :</span
          ><span class="text-red-600 font-bold">{{ product.price }}</span>
        </p>
      </div>
      <div>
        <p class="text-left">
          <span>ราคาเดิม :</span><span>{{ product.price_sale }}</span>
        </p>
      </div>
    </div>
    <div class="flex flex-col py-10 px-4 md:px-28">
      <div>
        <p class="underline font-bold text-left text-2xl">ตารางการผ่อนชำระ</p>
      </div>
      <div class="grid grid-cols-2 mt-5">
        <div class="py-5 price-color border-lefttop"><span> 60 งวด </span></div>
        <div class="py-5 border border-righttop">
          {{ product.price_installment_1 }}
        </div>
      </div>
      <div class="grid grid-cols-2">
        <div class="py-5 price-color"><span> 72 งวด </span></div>
        <div class="py-5 border">
          {{ product.price_installment_1 }}
        </div>
      </div>
      <div class="grid grid-cols-2">
        <div class="py-5 price-color border-leftbottom">
          <span> 84 งวด </span>
        </div>
        <div class="py-5 border border-rightbottom">
          {{ product.price_installment_1 }}
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 py-10 px-4 md:px-28">
      <div>
        <div>
          <p class="underline font-bold text-left text-2xl">รายละเอียด</p>
        </div>
        <div class="flex flex-col mt-5 mr-2">
          <div
            v-for="(item, key, index) in product.description"
            :key="key"
            :class="index % 2 == 0 ? 'table-color' : ''"
          >
            <div class="grid grid-cols-1 md:grid-cols-2 py-3">
              <div class="font-bold">
                {{ item.title }}
              </div>
              <div>
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <p class="underline font-bold text-left text-2xl">สิ่งที่ได้รับ</p>
        </div>
        <div class="flex flex-col mt-5 md:ml-2">
          <div
            v-for="(detail, index) in product.privilege_detail"
            :key="`privilege-${index}`"
            :class="index % 2 == 0 ? 'table-color' : ''"
          >
            <div class="py-3">
              {{ detail }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductDetail } from "@/api/product";
import GalleryItem from "@/components/GalleryItem";

export default {
  data() {
    return {
      product: {},
      images: [],
      index: null,
    };
  },
  components: {
    GalleryItem,
  },
  methods: {
    async setProductData(productId) {
      const response = await getProductDetail(productId);
      const data = await response.data;
      const product = data.data;
      const images = data.images;
      this.product = product;
      this.images = images;
    },
  },
  async mounted() {
    const params = new URLSearchParams(window.location.search);
    this.setProductData(params.get("id"));
  },
};
</script>

<style scoped>
.price-color {
  background-color: rgb(217, 229, 240);
}

.border-lefttop {
  border-radius: 10px 1px 1px;
}

.border-leftbottom {
  border-radius: 1px 1px 1px 10px;
}

.border-righttop {
  border-radius: 1px 10px 1px 1px;
}

.border-rightbottom {
  border-radius: 1px 1px 10px 1px;
}

.table-color {
  background-color: rgb(242, 242, 242);
}
</style>
